<template>
  <div>
  <!-- to write and send the support call -->
  <b-card>
    <b-card-header>
      <h5>Report Bug</h5>
    </b-card-header>

    <b-card-body>
      <b-row>
        <!-- title -->
        <b-col cols="12" md="6" xl="8" >
          <label>Title</label>
          <b-form-input v-model="report.titleReport"/>
        </b-col>
        <!-- type -->
        <b-col cols="12" md="5" xl="4" >
          <label>Typ</label>
          <trello-report-type-select-box v-model="report.type"/>
        </b-col>
      </b-row>
      <!-- Details -->
      <div>
        <label>Details</label>
        <b-textarea v-model="report.bodyReport" style="height: 200px"/>
      </div>
      <br>
      <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 100%">
        <b-button @click="sendReport" class="d-inline-block mr-1" size="sm" variant="outline-success" :disabled="loading">
          <b-spinner size="sm" type="grow" v-if="loading"></b-spinner>
          {{ $t('operations.save') }}
        </b-button>
        <b-button @click="resetReport" class="d-inline-block mr-1" size="sm" variant="outline-warning" :disabled="loading">
          {{ $t('operations.reset') }}
        </b-button>
<!--        <b-button v-b-modal.add-image class="d-inline-block mr-1" size="sm" variant="outline-info" :disabled="loading" @click="updateLink">-->
<!--          Send with image-->
<!--        </b-button>-->
      </div>
    </b-card-body>

  </b-card>



    <div>
      <b-modal id="add-image" title="Adding Image" v-model="active">
        <div>
          <input type="file" accept="image/*" @change="uploadImage($event)" id="file-input">
        </div>
      </b-modal>
    </div>


  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import mixinBase from '@/components/mixin/mixinBase';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import { mapActions } from 'vuex';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import TrelloReportTypeSelectBox from '@/components/widget/select/TrelloReportTypeSelectBox';
import axios from 'axios';

export default {
  name: 'Support',
  components: { TrelloReportTypeSelectBox, vueDropzone: vue2Dropzone, },
  mixins: [mixinBase,mixinPermissions, mixinNotifications, TrelloReportTypeSelectBox],

  data() {
    return {
      report: { titleReport: null, bodyReport: null, type: null },
      loading:false,
      dropzoneOptions: {
        url: '',
        method: 'POST', // Set the method to POST
        maxFilesize: 10, // Maximum file size in MB
        dictDefaultMessage: 'Drop files here or click to upload',
        dictMaxFilesExceeded: 'Only one file is allowed to upload',
        addRemoveLinks: true, // Show remove button for uploaded files
      },
      active: false,

    }
  },
  methods: {
     ...mapActions('bugReports', ['addNewReport', 'addNewReport_withImage']),

    updateLink(){
      if (this.report.titleReport !== null && this.report.bodyReport !== null) {
        this.dropzoneOptions.url = `http://localhost:8080/support/addwithimage?title=${encodeURIComponent(String(this.report.titleReport))}&body=${encodeURIComponent(String(this.report.bodyReport))}&reportType=${encodeURIComponent(String(this.report.type))}`;
        this.active= true;
        console.log("dropzone", this.dropzoneOptions)
      }
    },
    uploadImage(event) {
      this.loading= true;
      let $this = this
      let data = new FormData();
      data.append('name', 'my-picture');
      data.append('file', event.target.files[0]);
      console.log("in the uploead 1");
      let rep= {bugReport: this.report, file: event.target.files[0], data: data}

      this.addNewReport_withImage(rep).then(response=>{
        console.log("apter th update");
        this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: "Bug report" }));
        this.resetReport()
      },err=>{
        this.showServerErrorNotification("createDataError", "contact", err)
      }).finally(()=>{
        this.loading= false;
        this.active= false
      })

      //
      // let config = {
      //   header : {
      //     'Content-Type' : 'image/png'
      //   }
      // }
      // let URL = "http://localhost:8080/support/addwithimage3/"//this.dropzoneOptions.url;
      // axios.put(
      //     URL,
      //     data,
      //     config
      // ).then(
      //     response => {
      //       console.log('image upload response > ', response)
      //     }
      // ).finally(()=>{
      //         this.loading= false;
      //       })

      //
      // axios.put(
      //     URL,
      //     data,
      //     config
      // ).then(
      //     response => {
      //       console.log('image upload response > ', response)
      //     }
      // )
    },
    onDropzoneSuccess(file, response) {
      this.showSuccessNotification(this.$t('globalMessages.addDataSuccess', { system: this.$t('menu.kyc') }));
      // Close the modal after successful upload
      this.active=false;
      this.resetReport()
    },



    sendReport() {
      this.loading= true;
      let $this = this
      let rep= {bugReport: this.report}
      this.addNewReport(rep).then(response=>{
        this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: "Bug report" }));
        this.resetReport()

      },err=>{
        this.showServerErrorNotification("createDataError", "contact", err)
      }).finally(()=>{
        this.loading= false;
      })
    },
    resetReport(){
       this.report.titleReport = null
       this.report.bodyReport = null
       this.report.type = null
    },



  }


};
</script>

<style scoped>
.uploading-image{
  display:flex;
}
</style>
