<template>
  <v-select
      v-model="model"
      v-bind:multiple="multiple"

      :options="trelloListTypes"
      label="name"
      :reduce="(org) => org.id"
      v-bind="selectProps"
      :clearable="clearable"
  >
    <template #selected-option="org">
      <div class="text-center" v-if="acronym != null && acronym !== false">
        <b-avatar size="25"  class="d-inline-block" v-b-tooltip  :title="org.name">{{initialName(org.name)}}</b-avatar>
      </div>
      <span v-else>{{ org.name }}</span>
    </template>
  </v-select>

</template>

<script>
import { initialName } from '@/model/Utils';

export default {
  name: 'TrelloReportTypeSelectBox',
  data: () => ({
    trelloListTypes: [{"name": "TODO CRM", "id": "657a358e17c7a4728bce583c"},
      {'name': "TODO CORE", "id": "657a376811992f9a66b35234"},
      {'name': "TODO CLIENT", "id": "657a375f811c1d00fca9d183"}],
  }),
  props: {
    value: {
      required: true,
    },
    acronym: {
      default: () => false
    },
    multiple:{
      type: Boolean,
      default: () => false
    },
    clearable:{},
    selectProps:{
      default: () => ({})
    }
  },
  computed:{
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    }
  },
  methods:{
    initialName
  }

};
</script>

<style scoped>

</style>
